<template>
  <div class="mt-30px lg:(mt-60px)">
    <div class="lg:(w-1060px mx-auto)">
      <!-- title -->
      <div class="leading-inst text-28px text-inst000 font-600 text-center md:(w-680px mx-auto text-inst36) lg:(leading-inst text-52px text-inst111 font-600 text-center)">
        {{ $t('faq.t1') }}
      </div>
      <!-- FAQ -->
      <section class="mt-42px px-inst22 lg:(mt-66px px-0px)">
        <faq :newsList="list" />
      </section>
    </div>
  </div>
</template>

<script>
import faq from '@/components/faq.vue'

export default {
  components: {
    faq
  },
  data () {
    return {
      list: [
        {
          title: 'faq.faq1.title',
          content: ['faq.faq1.content1', 'faq.faq1.content2', 'faq.faq1.content3', 'faq.faq1.content4'],
          expand: false
        },
        {
          title: 'faq.faq2.title',
          content: ['faq.faq2.content1'],
          expand: false
        },
        {
          title: 'faq.faq3.title',
          content: ['faq.faq3.content1', 'faq.faq3.content2'],
          expand: false
        },
        {
          title: 'faq.faq4.title',
          content: ['faq.faq4.content1'],
          expand: false
        },
        {
          title: 'faq.faq5.title',
          content: ['faq.faq5.content1'],
          expand: false
        },
        {
          title: 'faq.faq6.title',
          content: ['faq.faq6.content1'],
          expand: false
        },
        {
          title: 'faq.faq7.title',
          content: ['faq.faq7.content1'],
          expand: false
        },
        {
          title: 'faq.faq8.title',
          content: ['faq.faq8.content1'],
          expand: false
        }
      ]
    }
  }
}
</script>
